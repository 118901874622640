import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LandModel } from '../../lands/land.model';
import { LandsService } from '../../lands/lands.service';
import { MapService } from '../map.service';
import Map from 'ol/Map';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import * as Proj from 'ol/proj';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import { Style, Fill, Stroke, Circle, Icon } from 'ol/style';
import Overlay from 'ol/Overlay';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {transform} from 'ol/proj';


@Component({
  selector: 'app-lands-overview',
  templateUrl: './lands-overview.component.html',
  styleUrls: ['./lands-overview.component.scss']
})
export class LandsOverviewComponent implements OnInit {

  constructor(private landsService: LandsService, private mapService:MapService) {
    this.landsService.landsModified.subscribe(
      lands => {
        this.removeLayers().then((result) => {
          for(let i=0;i<lands.length;i++){
            if('plots' in lands[i]){
              this.printLandsOverview(lands);
              break;
            }
          }
        });
      }
    )
  }

  ngOnInit(): void {
  }

  styleFunction = function(feature) {
    // marc pixel
    // console.log("canta des d'aqui? 3")

    return this.mapService.getStyle(feature);
  }.bind(this);

  styleFunctionForIcon = function(f){
    return this.mapService.getStyleForIcon(f)
  }.bind(this);

  printLandsOverview(lands){
    console.log('+++++++++++++++++++++++++++++++++=printLandsOverview')
    let map = this.mapService.getMap();
    var vectorSource2 = new VectorSource();
    var vectorSourceS = new VectorSource();
    // plots
    var vectorLayer2 = new VectorLayer({
      source: vectorSource2,
      style: this.styleFunction
    });
    vectorLayer2.set('name','land')
    // Point plots
    var vectorLayerS = new VectorLayer({
      source: vectorSourceS,
      style: this.styleFunctionForIcon
    });
    vectorLayerS.set('name','land');

    var where;
    let plotsPerimeter = [];
    for(let i=0;i<lands.length;i++){
      // add plots to map
      // console.log(lands[i]);
      //let url = lands[i].pk;
      /* switch(lands[i]['planet']){
        case 'info':
        url="{% url 'lands:info_lands' 123 %}";
        break;
        case 'cubierta':
        url="{% url 'lands:cubierta_lands' 123 %}";
        break;
        case 'glass':
        url="{% url 'lands:glasses_land' 123 %}";
        break;
        case 'go40':
        url="{% url 'lands:detail_go40' 123 %}";
        break;
        default:
        url="{% url 'lands:detail' 123 %}";
        break;
      } */
      if(lands[i]['plots']){
        for(let j=0;j<lands[i]['plots'].length;j++){
          let plot = lands[i]['plots'][j];
          // console.log(plot['coordinates']);
          // console.log(plot['coordinates'][0].coordinates_values);
          if(!plot['coordinates'][0] || plot['coordinates'].length < 1){
            break;
          }
          let polygon, ring;
          if(!plot['coordinates'][0].includes(']]')){
            ring= JSON.parse('['+plot['coordinates'][0]+']');
            where = ring[0];
            polygon = new Polygon([ring]);
          }else{
            ring= JSON.parse('[['+plot['coordinates'][0]+']]');
            where = ring[0][0];
            polygon = new MultiPolygon([ring]);
          }
          polygon.transform('EPSG:4326', 'EPSG:3857');
          // Create feature with polygon.
          // console.log(ring);
          var feature = new Feature(polygon);
          //console.log(feature)
          //var parcelUrl = plot.pk;
          //var htmlpopup = `<p>Land: <a href="${url}">${lands[i].name}</a></p><p>Plot: <a href="${parcelUrl}?landid=${lands[i].pk}">${plot.name}</a></p>`;
          //feature.set('name', htmlpopup);
          feature.set('landName', lands[i].name);
          feature.set('satellite', lands[i].satellite );
          feature.set('winter', lands[i].winter );
          feature.set('summer', lands[i].summer );
          feature.set('active',lands[i].active);
          feature.set("type","land");
          feature.set("pk_plot", plot.pk);
          feature.set('plotName', plot.name);
          vectorSource2.addFeature(feature);
        }

        // add icon to land on map
        var iconFeature = new Feature({
          geometry: new Point(transform(where, 'EPSG:4326','EPSG:3857')),
          name: lands[i].name,
          //season: lands[i]['season'],
          active: lands[i].active,
          population: 4000,
          rainfall: 500
        });
        iconFeature.set('landName', lands[i].name);
        iconFeature.set('satellite', lands[i].satellite );
        iconFeature.set('winter', lands[i].winter );
        iconFeature.set('summer', lands[i].summer );
        iconFeature.set('active',lands[i].active);
        iconFeature.set("type","icon");
        vectorSourceS.addFeature(iconFeature);
        if(where.length < 4){
          plotsPerimeter.push(where)
        }
        // plotsPerimeter.push(where)
      }
      /* if(lands[i]['season']!='all'){
        $('#filterAllyear').prop('disabled',false);
        if(lands[i]['season']=='summer'){
          $('#filterSummer').prop('disabled',false);
        }else if(lands[i]['season']=='winter'){
          $('#filterWinter').prop('disabled',false);
        }
      } */
    }
    //if(onlyactivelands){
      map.addLayer(vectorLayerS);
      vectorLayerS.setZIndex(3);
      map.addLayer(vectorLayer2);
      vectorLayer2.setZIndex(2);

      // centers view and zoom if there's more than 1 plot
      if (plotsPerimeter.length > 1) {
        // closes the perimeter
        plotsPerimeter.push(plotsPerimeter[0]);
        let polygon = new Polygon([plotsPerimeter]);
        polygon.transform('EPSG:4326', 'EPSG:3857');
        let feature = new Feature(polygon);
        const extent = feature.getGeometry().getExtent();
        
        // Check if extent is valid
        if (extent && !extent.some(value => !isFinite(value))) {
          map.getView().fit(extent, { 
            constrainResolution: false,
            padding: [20, 20, 20, 20],
            duration: 1000
          });
        } else {
          // Fallback to center if extent is invalid
          map.getView().setCenter(transform(where, 'EPSG:4326', 'EPSG:3857'));
        }
      } else if (where) {
        map.getView().setCenter(transform(where, 'EPSG:4326', 'EPSG:3857'));
      }
    //}
    /* map.on('click', function (evt) {
      var feature = map.forEachFeatureAtPixel(evt.pixel,
        function (feature) {
          return feature;
        });
      setTimeout(function () {
        if (feature) {
          //console.log(feature);
          var coordinates = feature.getGeometry().getCoordinates();
          //if (coordinates) {

          //console.log(coordinates);
          //var res = coordinates.split(",");
          var where = [];
          where[0] = coordinates[0][1][0];
          where[1] = coordinates[0][1][1];
          console.log(this.popup);
          this.popup.nativeElement.innerHTML = feature.get('name')
          this.popup.nativeElement.hidden = false;
          this.popup.setPosition(evt.coordinate);
        } else {
          this.popup.nativeElement.innerHTML = ''
          this.popup.nativeElement.hidden = true;
        }
      }, 200);
    }); */
    this.mapService.landsOnMap();
  }
  removeLayers(){
    console.log('remove layers')
    return new Promise((resolve, reject) => {
      let map = this.mapService.getMap();
      map.getLayers().forEach(function (layer) {
        // console.log(layer)
        if (layer instanceof VectorLayer) {
          console.log(layer)
          map.removeLayer(layer);
        }
      });
      resolve(true)
    });
  }
}
