import { Injectable } from "@angular/core";
import { WeatherService } from "../../../lands/land-detail/weather/weather.service";
import { MapService } from "src/app/estratos-components/map/map.service";

@Injectable({
  providedIn: "root",
})
export class GraphPlotsService {
  constructor(private mapService: MapService, private weatherService: WeatherService) {}

  cleanValuesPlotFiles(val) {
    // console.log(this.mapService.getIndex())
    let index = this.mapService.getIndex();
    // console.log(index)
    let fin_index = index;
    if (index == "ndvi absolute" || index == "ndvi relative") {
      fin_index = "3";
    }
    let final = [];
    let avgs = [];
    let cvs = [];
    for (let i = 0; i < val.length; i++) {
      let file = val[i];
      // console.log(file['Date_timeline'])
      if (file["classes"] == fin_index && file["Date_timeline"]) {
        let dateAverage2 = new Date(file["Date_timeline"]);
        // console.log(dateAverage2)
        let year = 0;
        year = dateAverage2.getFullYear();
        // console.log(dateAverage2.getFullYear())
        let dateAverage = 0;
        dateAverage = dateAverage2.getTime();
        avgs.push([dateAverage, parseFloat((+file["extra_average"]).toFixed(3)), 0, file["pk"]]);
        cvs.push([dateAverage, +(+file["extra_std"] / +file["extra_average"]).toFixed(3)]);
      }
    }
    final.push(avgs);
    final.push(cvs);
    // console.log(final_1)
    for (let j = 0; j < final[0].length; j++) {
      //console.log(values2017[i]);
      if (j == 0) {
        final[0][j][2] = 0;
      } else {
        final[0][j][2] = Math.floor(((final[0][j][1] - final[0][j - 1][1]) / final[0][j - 1][1]) * 100);
      }
    }
    let first_file = new Date().getTime();
    let last_file = new Date(0).getTime();
    for (let k = 0; k < final[0].length; k++) {
      let date = final[0][k][0];
      if (date < first_file) {
        first_file = final[0][k][0];
      }
      if (date > last_file) {
        last_file = final[0][k][0];
      }
    }
    return final;
  }
  cleanValuesPlotFilesYear(val) {
    // console.log(this.mapService.getIndex())
    let index = this.mapService.getIndex();
    // console.log(index)
    let fin_index = index;
    if (index == "ndvi absolute" || index == "ndvi relative") {
      fin_index = "--";
    }
    let final = [];
    let avgs = [];
    let cvs = [];
    for (let i = 0; i < val.length; i++) {
      let file = val[i];
      // console.log(file['Date_timeline'])
      if ((file["classes"] == "3" && file["Date_timeline"]) || (file["classes"] == fin_index && file["Date_timeline"])) {
        let dateAverage2 = new Date(file["Date_timeline"]);
        // console.log(dateAverage2)
        let year = 0;
        year = dateAverage2.getFullYear();
        // console.log(dateAverage2.getFullYear())
        let dateAverage = 0;
        dateAverage = dateAverage2.getTime();
        avgs.push([dateAverage, parseFloat((+file["extra_average"]).toFixed(3)), 0, file["pk"], file["classes"]]);
        if (file["classes"] == "3") {
          cvs.push([dateAverage, +(+file["extra_std"] / +file["extra_average"]).toFixed(3)]);
        }
      }
    }
    final.push(avgs);
    final.push(cvs);
    // console.log(final_1)
    for (let j = 0; j < final[0].length; j++) {
      //console.log(values2017[i]);
      if (j == 0) {
        final[0][j][2] = 0;
      } else {
        final[0][j][2] = Math.floor(((final[0][j][1] - final[0][j - 1][1]) / final[0][j - 1][1]) * 100);
      }
    }
    let first_file = new Date().getTime();
    let last_file = new Date(0).getTime();
    for (let k = 0; k < final[0].length; k++) {
      let date = final[0][k][0];
      if (date < first_file) {
        first_file = final[0][k][0];
      }
      if (date > last_file) {
        last_file = final[0][k][0];
      }
    }
    return final;
  }
  cleanValuesPlotWeather(extra_values) {
    //Clean Weather
    let plot_values_extra = [];
    let rains = [];
    let temps = [];
    let humid = [];
    let nHist = 0;
    let nowRain = 0;
    let todayHist = "";
    let lastDayHist = 0;
    let avgTmp = [];
    if (extra_values["weather_hist"]) {
      // console.log(extra_values["weather_hist"]);
      for (let t = 0; t < extra_values["weather_hist"].length; t++) {
        let histo = extra_values["weather_hist"][t]["weatherHist_now"];
        // console.log(histo);
        let json_hist = JSON.parse(histo.replace(/'/g, '"'));
        // console.log(json_hist);
        if (json_hist.humidity) {
          // humid.push([Date.parse(extra_values['weather_hist'][t]['weatherHist_time']), json_hist.humidity])
          if (json_hist.summary == "BenchmarkLabs") {
            humid.push([Date.parse(extra_values["weather_hist"][t]["weatherHist_time"]), json_hist.humidity]);
          } else {
            humid.push([Date.parse(extra_values["weather_hist"][t]["weatherHist_time"]), json_hist.humidity * 100]);
          }
        }
        if (json_hist.cod == 200) {
          if (json_hist) {
            todayHist = "";
            var totAvg = 0;
            var lengAv = avgTmp.length;
            for (var i = 0; i < lengAv; i++) {
              totAvg = totAvg + avgTmp[i];
            }
            rains[nHist] = [lastDayHist, nowRain];
            temps[nHist] = [
              lastDayHist,
              parseFloat((totAvg / lengAv).toFixed(1)),
              json_hist.clouds.all / 100,
              json_hist.main.humidity,
            ];
            nowRain = 0;
            //console.log(nowRain);
            nHist = nHist + 1;
          }
          if (todayHist == "") {
            //console.log('Initial');
            //console.log(json_hist.sys.sunrise *1000);
            avgTmp.push(Math.floor(json_hist.main.temp - 273.15));
            if (json_hist.rain) {
              nowRain = nowRain + json_hist.rain["3h"];
            }
            todayHist = extra_values["weather_hist"][t]["weatherHist_time"];
            lastDayHist = json_hist.sys.sunrise * 1000;
          } else {
            if (todayHist == extra_values["weather_hist"][t]["weatherHist_time"]) {
              //console.log('New date');
              //console.log(nowRain);
              avgTmp.push(Math.floor(json_hist.main.temp - 273.15));
              if (json_hist.rain) {
                nowRain = nowRain + json_hist.rain["3h"];
              }
              // if(numberN == 5 || numberN == 23 || numberN == 26 || numberN == 29 || numberN == 45|| numberN == 32|| numberN == 30|| numberN == 31|| numberN == 33|| numberN == 34|| numberN == 35){
              //   nowRain = nowRain + Math.floor(Math.random() * 30);
              // }
              lastDayHist = json_hist.sys.sunrise * 1000;
            } else {
              //console.log('End date');
              //console.log(avgTmp);
              var totAvg = 0;
              var lengAv = avgTmp.length;
              for (var i = 0; i < lengAv; i++) {
                totAvg = totAvg + avgTmp[i];
              }
              rains[nHist] = [lastDayHist, nowRain];
              temps[nHist] = [
                lastDayHist,
                parseFloat((totAvg / lengAv).toFixed(1)),
                json_hist.clouds.all / 100,
                json_hist.main.humidity,
              ];
              nowRain = 0;
              avgTmp = [];
              avgTmp.push(Math.floor(json_hist.main.temp - 273.15));
              if (json_hist.rain) {
                nowRain = nowRain + json_hist.rain["3h"];
              }
              //console.log(nowRain);
              nHist = nHist + 1;
              todayHist = extra_values["weather_hist"][t]["weatherHist_time"];
            }
          }
        } else {
          if (json_hist.cod != 500) {
            //console.log('DARKSKY')
            if (todayHist == "") {
              //console.log('Initial DK');
              //console.log(json_hist.time);
              avgTmp.push(parseFloat(json_hist.temperature));
              if (json_hist.precipAccumulation) {
                nowRain = nowRain + json_hist.precipAccumulation;
              }
              todayHist = extra_values["weather_hist"][t]["weatherHist_time"];
              lastDayHist = json_hist.time * 1000;
            } else {
              //console.log(json_hist.time);
              if (todayHist == extra_values["weather_hist"][t]["weatherHist_time"]) {
                //console.log('New date DK');
                //console.log(nowRain);
                avgTmp.push(parseFloat(json_hist.temperature));
                if (json_hist.precipAccumulation) {
                  nowRain = nowRain + json_hist.precipAccumulation;
                }
                // if(numberN == 5 || numberN == 23 || numberN == 26 || numberN == 29 || numberN == 45|| numberN == 32|| numberN == 30|| numberN == 31|| numberN == 33|| numberN == 34|| numberN == 35){
                //   nowRain = nowRain + Math.floor(Math.random() * 30);
                // }
                lastDayHist = json_hist.time * 1000;
              } else {
                //console.log('End date DK');
                //console.log(avgTmp);
                var totAvg = 0;
                var lengAv = avgTmp.length;
                for (var i = 0; i < lengAv; i++) {
                  totAvg = totAvg + avgTmp[i];
                }
                rains[nHist] = [lastDayHist, nowRain];
                temps[nHist] = [lastDayHist, parseFloat((totAvg / lengAv).toFixed(1)), 0, 0];
                nowRain = 0;
                avgTmp = [];
                avgTmp.push(parseFloat(json_hist.temperature));
                if (json_hist.precipAccumulation) {
                  nowRain = nowRain + json_hist.precipAccumulation;
                }
                //console.log(nowRain);
                nHist = nHist + 1;
                todayHist = extra_values["weather_hist"][t]["weatherHist_time"];
              }
            }
          }
        }
      }
    }
    // console.log(extra_values)
    humid = humid.sort((a, b) => a[0] - b[0]);
    // marc pixel
    //let new_humid = 0
    let new_humid = this.condensateHumidity(humid);
    // marc pixel
    // console.log(new_humid)
    plot_values_extra.push(temps);
    plot_values_extra.push(rains);
    plot_values_extra.push(new_humid);
    // plot_values_extra.push(this.eotFunctionOld(extra_values['latlon'],plot_values_extra))
    plot_values_extra.push(this.weatherService.eotFunctionHist(extra_values["latlon"], plot_values_extra));
    // this.eotFunctionOld(extra_values['latlon'])
    // console.log(plot_values_extra)
    return plot_values_extra;
  }
  condensateHumidity(humidityFromWeather) {
    let currentdate = humidityFromWeather[humidityFromWeather.length - 1][0],
      counter = 1;
    for (let i = humidityFromWeather.length - 2; i >= 0; i--) {
      if (currentdate == humidityFromWeather[i][0]) {
        humidityFromWeather[i + 1][1] += humidityFromWeather[i][1];
        counter++;
        humidityFromWeather.splice(i, 1);
      } else {
        humidityFromWeather[i + 1][1] = humidityFromWeather[i + 1][1] / counter;
        currentdate = humidityFromWeather[i][0];
        counter = 1;
      }
    }
    return humidityFromWeather;
  }
  eotFunctionOld(latValSt, plot_values_extra) {
    let latVal = JSON.parse(latValSt);
    // console.log(latVal);
    // console.log(plot_values_extra);
    let eotVal = [];
    let julian = [];
    let latAux = this.toDegreesMinutesAndSeconds(latVal[1]);
    // console.log(latAux);
    let latRad = (Math.PI / 180) * latAux;
    // console.log(latRad);
    for (var li = 0; li < plot_values_extra[0].length; li++) {
      // console.log('Times');
      // console.log(this.toJulianDate(new Date(plot_values_extra[0][li][0])));
      julian[li] = this.toJulianDate(new Date(plot_values_extra[0][li][0]));
      let dr = 1 + 0.033 * Math.cos(((2 * Math.PI) / 365) * julian[li]);
      // console.log(dr);
      let ds = 0.409 * Math.sin(2 * ((Math.PI * julian[li]) / 365) - 1.39);
      // console.log(ds);
      let ws = Math.acos(-1 * Math.tan(latRad) * Math.tan(ds));
      // console.log(Math.tan(latRad));
      // console.log(Math.acos(Math.tan(latRad)*Math.tan(ds)));
      // console.log(ws);
      let ra =
        ((24 * 60) / Math.PI) *
        0.082 *
        dr *
        (ws * Math.sin(latRad) * Math.sin(ds) + Math.cos(latRad) * Math.cos(ds) * Math.sin(ws));
      // console.log(ra);
      let ram = ra * 0.408;
      let evohav = 0.0023 * (plot_values_extra[0][li][1] + 17.78) * ram * Math.pow(10, 0.5);
      // console.log(evohav);
      eotVal[li] = [];
      eotVal[li][0] = plot_values_extra[0][li][0];
      eotVal[li][1] = parseFloat(evohav.toFixed(3));
    }
    // console.log('ETOOOOOSSS');
    // console.log(eotVal);
    return eotVal;
  }
  toJulianDate(valu) {
    var j = Math.floor((valu.getTime() - new Date("Dec 30," + (valu.getFullYear() - 1) + " 23:00:00").getTime()) / 86400000);
    // console.log(j)
    // let i=3-j.length;
    // while(i-->0)j=0+j;
    return j;
  }
  toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees;
  }
}
