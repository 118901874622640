import { Injectable } from "@angular/core";
import Map from "ol/Map";
import Layer from "ol/layer/Layer";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import { Style, Fill, Stroke, Circle, Icon } from "ol/style";
import { Plot } from "../../lands/lands/plots/plot.model";
import { getCenter } from "ol/extent";

@Injectable()
export class MapCenterView {
  /*
    grabs all features from land
    adds them to "centers" vectorsource
    fits the map to "centers"
     */
  toPlots(map: Map, plots: Plot[]) {
    if (!map || !plots || plots.length === 0) {
      return;
    }

    let centers = new VectorSource();
    let hasValidFeatures = false;

    map.getLayers().forEach(function (layer) {
      if (layer instanceof Layer) {
        if (layer.get("name") == "land") {
          let source = layer.getSource();
          if (source.getFeatures().length > 0) {
            source.getFeatures().forEach(function (feature) {
              for (let i = 0; i < plots.length; i++) {
                let plot = plots[i];
                if (plot.pk == feature.get("pk_plot")) {
                  centers.addFeature(feature);
                  hasValidFeatures = true;
                  break;
                }
              }
            });
          }
        }
      }
    });

    if (!hasValidFeatures) {
      return;
    }

    const extent = centers.getExtent();
    if (!extent || extent.some(value => !isFinite(value))) {
      return;
    }

    map.getView().fit(extent, {
      padding: [100, 100, 100, 100],
      duration: 1000,
      callback: () => {
        const view = map.getView();
        const currentExtent = centers.getExtent();
        const currentResolution = view.getResolution();

        if (currentResolution && currentExtent && !currentExtent.some(value => !isFinite(value))) {
          const extentWidth = Math.abs(currentExtent[2] - currentExtent[0]);
          const extentHeight = Math.abs(currentExtent[3] - currentExtent[1]);
          const zoomOutFactor = Math.max(extentWidth, extentHeight) > 10000 ? 1.3 : 1.5;

          view.animate({
            resolution: currentResolution * zoomOutFactor,
            duration: 500,
          });
        }
      },
    });
  }
}
